import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "prepopulating-transaction-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#prepopulating-transaction-attributes",
        "aria-label": "prepopulating transaction attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prepopulating transaction attributes`}</h1>
    <p>{`By default, the SDK will prompt users to enter all required attributes such as funder, provider and claim items to process a transaction. However, those attributes can be completely prepopulated from your system with the Medipass UI acting as a simple validation and error handling.`}</p>
    <blockquote>
      <p parentName="blockquote">{`All of the top-level attributes listed below are optional to create a claim - only set what you wish. If an attribute is required by a particular fund, the user will be prompted to enter the value prior to claim submission.`}</p>
    </blockquote>
    <p>{`Available attributes:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#virtual-terminal"
        }}>{`Virtual Terminal`}</a></li>
      <li parentName="ul">{`Funders`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#generic-attributes-applicable-to-all-funders"
            }}>{`Generic attributes`}</a></li>
          <li parentName="ul">{`Funder-specific attributes`}
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#comcare-attributes"
                }}>{`Comcare`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#dva-attributes"
                }}>{`DVA`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#hicaps-attributes"
                }}>{`HICAPS`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#icare-attributes"
                }}>{`icare`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#medicare-attributes"
                }}>{`Medicare`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#workcover-queensland-attributes"
                }}>{`Workcover Queensland`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h2 {...{
      "id": "virtual-terminal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#virtual-terminal",
        "aria-label": "virtual terminal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual terminal`}</h2>
    <h4 {...{
      "id": "schema",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema",
        "aria-label": "schema permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  platform: 'virtual-terminal',
  providerNumber: string,
  invoiceReference: string,
  chargeAmount: string,
  paymentMethod: "new-payment-card" | "phone" |"payment-link",
  patient: {
    mobile: string
  }
})
`}</code></pre>
    <h4 {...{
      "id": "reference",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reference",
        "aria-label": "reference permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually the Medicare Provider Number. Else the funder specific provider number. Should be 8 characters alpha-numeric. This will change the cardholder's payment narrative and settlement if different rules are set for given provider and practice. Example: 1234565X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceReference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider nominated unique transaction reference number, such as an invoice number from the practice management or accounting system.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`chargeAmount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount to charge for the virtual terminal transaction. Example: 10.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`paymentMethod`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"new-payment-card", "phone" or "payment-link"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the payment should be processed by the provider as a one-off manual entry `}<strong parentName="td">{`new-payment-card`}</strong>{`, the payment request should be sent `}<strong parentName="td">{`via SMS`}</strong>{` to the patient or to generate a `}<strong parentName="td">{`payment-link`}</strong>{` for email or inclusion in an invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The mobile phone number of the patient. Can be either international notation: +61411111111 or national notation: 0411111111`}</td>
        </tr>
      </tbody>
    </table>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h2 {...{
      "id": "funders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#funders",
        "aria-label": "funders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Funders`}</h2>
    <h3 {...{
      "id": "generic-attributes-applicable-to-all-funders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#generic-attributes-applicable-to-all-funders",
        "aria-label": "generic attributes applicable to all funders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Generic attributes (applicable to all funders)`}</h3>
    <p>{`These fields are common across funders. If you choose to limit integration efforts but wish to support the widest range of claiming scenarios, then the following fields could be used to set most required claim and payment attributes.`}</p>
    <h4 {...{
      "id": "schema-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema-1",
        "aria-label": "schema 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  funder: "comcare" | "dva" | "hicaps" | "patient-funded" | "icare" | "medicare" | "wcq"

  providerNumber: string,

  invoiceReference: string,

  patientId: string,
  // OR
  patient: {
    refId: string,
    firstName: string,
    lastName: string,
    mobile: string,
    dob: string,
    accountNumber: string,
    reference: string
  },

  claimableItems: [{
    itemCode: string,
    reference: string,
    serviceDateString: string,
    price: string,
    isGross: boolean,
    quantity: number,
    isTaxable: boolean
  }],

  webhooks: [{
    url: string,
    event:
      'invoiceBalancePaid' |
      'invoiceCancelled' |
      'invoiceCompleted' |
      'healthFundApprovedInvoice' |
      'healthFundRejectedInvoice',
    method: 'GET' | 'PUT' | 'POST' | 'DELETE',
    headers: { [key: string]: string }
  }],

  funderData: {
    /* see below for funder-specific attributes */
  }
}, {
  onSuccess: function (transaction) { /* ... */ },
  onError: function (error) { /* ... */ },
  onCancel: function () { /* ... */ }
})
`}</code></pre>
    <h4 {...{
      "id": "reference-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reference-1",
        "aria-label": "reference 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually the Medicare Provider Number. Else the funder specific provider number. Should be 8 characters alpha-numeric. Example: 1234565X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`funder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Example: `}<inlineCode parentName="td">{`"hicaps"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"dva"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"medicare"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"patient-funded"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"wcq" "wsv"`}</inlineCode>{`. `}<br />{` If no funder is selected, the provider will be prompted to select a funder.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceReference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider nominated unique transaction reference number, such as an invoice number from the practice management or accounting system.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patientId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass Business Patient ID. If supplied and valid, this value will pre-populate patient details from the patient record in the business.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OR`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.refId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A patient unique reference as set by you. If supplied and valid, this value will pre-populate patient details from the patient record in the business. Any patient details sent for an existing patient record will update those values`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OR`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient first name as registered with funder. Middle name is not supported. If patient has only one name, then set name in lastName field and set firstName to “Onlyname”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient last name as registered with funder.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.dob`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient Date of Birth in ‘YYYY-MM-DD’ format. Example: ‘1967-11-02’`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The mobile phone number of the patient. Can be either international notation: +61411111111or national notation: 0411111111`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.accountNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique funder issued account number for patient`}<br />{`Maps to: medicareNumber (Medicare), dvaNumber (DVA), membershipNumber (HICAPS), claimNumber (icare) , claimNumber (Workcover Queensland)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique patient reference with funder`}<br />{` Maps to: medicareReferenceNumber (Medicare), cardRank(HICAPS/PHI patient reference or individual identifier).`}<br />{`Field ignored if cardRank is not applicable for funder.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array{Object}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of fund claimable items`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].serviceDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the service in 'YYYY-MM-DD' format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].itemCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Item code - note item code must be valid for funder type and as of the service date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reference for claim item. Has to be unique for the the entire claim`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].price`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Net (excluding GST) price`}</strong>{` each In $XX.XX format. Example ‘$15.95’. Note: If this is the gross amount (including GST), set the `}<inlineCode parentName="td">{`isGross`}</inlineCode>{` flag below to `}<inlineCode parentName="td">{`true`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].isGross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to `}<inlineCode parentName="td">{`true`}</inlineCode>{`, this will indicate the `}<inlineCode parentName="td">{`price`}</inlineCode>{` is the gross amount (including GST).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only used where provider description is required for a given itemCode.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].quantity`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for `}<strong parentName="td">{`icare`}</strong>{` & `}<strong parentName="td">{`Workcover Queensland`}</strong>{`. Denotes number of services or pieces of equipment provided as part of this service. Where funder has item code set to ignore quantity, field is ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].isTaxable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for `}<strong parentName="td">{`icare`}</strong>{` & `}<strong parentName="td">{`Workcover Queensland`}</strong>{`. Set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` if GST tax is applicable for item. Where funder has item code as tax free, field is ignored. maps to gst fields.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].clinicalCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for supported claim items (eg. tooth number in hicaps for dental claim items). Note: clinicalCode will be applied to claim items which supports it and ignored otherwise.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].extras.duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for `}<strong parentName="td">{`DVA`}</strong>{` and `}<strong parentName="td">{`Medicare`}</strong>{`. Time duration (in minutes) of the service item, in increments of 15. Example: `}<inlineCode parentName="td">{`"15"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"30"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"45"`}</inlineCode>{`, etc`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].extras.secondDevice`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"Y"`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`"N"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for `}<strong parentName="td">{`DVA`}</strong>{`. `}<inlineCode parentName="td">{`"Y"`}</inlineCode>{` for Yes, `}<inlineCode parentName="td">{`"N"`}</inlineCode>{` for No.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].extras.treatmentLocation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"V"`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`"H"`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`"R"`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`"N"`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`"C"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for `}<strong parentName="td">{`DVA`}</strong>{`.                                                                                         `}<inlineCode parentName="td">{`"V"`}</inlineCode>{` for Home Visit. `}<inlineCode parentName="td">{`"H"`}</inlineCode>{` for Hospital. `}<inlineCode parentName="td">{`"R"`}</inlineCode>{` for Rooms. `}<inlineCode parentName="td">{`"N"`}</inlineCode>{` for Residential Care Facility. `}<inlineCode parentName="td">{`"C"`}</inlineCode>{` for Community Health Centres.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].extras.distanceTravelled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for `}<strong parentName="td">{`DVA`}</strong>{`.                                                                                        Distance travelled in kilometres.`}</td>
        </tr>
      </tbody>
    </table>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "comcare-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#comcare-attributes",
        "aria-label": "comcare attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comcare attributes`}</h3>
    <p>{`These fields apply to claims processed via Comcare.`}</p>
    <p>{`The following general field are not applicable for Comcare claims and are ignored:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`patient.mobile`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`patient.email`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`patient.reference`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`nonClaimableItems`}</inlineCode></li>
    </ul>
    <p>{`The Medipass Comcare integration supports provider submission of supporting documentation, such as certificates of fitness/capacity, surgical/operation reports, allied health recovery requests and similar. However, these attachments must be added in the Medipass modal or iframe user interface.`}</p>
    <h4 {...{
      "id": "schema-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema-2",
        "aria-label": "schema 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction(
  {
    /* ... generic attributes from above */

    patient: {
      ...
      accountNumber: string, // maps to Comcare claim number
      ...
    }

    funderData: {
      comcare: {
        serviceNotes: string
      }
    }
  },
  {
    onSuccess: function(transaction) {
      /* ... */
    },
    onError: function(error) {
      /* ... */
    },
    onCancel: function() {
      /* ... */
    }
  }
);
`}</code></pre>
    <h4 {...{
      "id": "reference-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reference-2",
        "aria-label": "reference 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`serviceNotes`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`512`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Service notes for the transaction. Usually used for claims with exceptions.`}</td>
        </tr>
      </tbody>
    </table>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "dva-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#dva-attributes",
        "aria-label": "dva attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DVA attributes`}</h3>
    <blockquote>
      <p parentName="blockquote">{`For all DVA transactions, it’s important the patient details match exactly to that listed on a DVA card rather than what may be recorded in your system. Medipass will prompt the user to verify and update details where account details vary from those registered with DVA.`}</p>
    </blockquote>
    <p>{`The following general field are not applicable for DVA claims and are ignored:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].description`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].quantity`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].unit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].isTaxable`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "schema-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema-3",
        "aria-label": "schema 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction(
  {
    /* ... generic attributes from above */

    funderData: {
      lspn: string,
      facilityId: string,
      isInHospital: boolean,
      treatmentLocation: 'V' | 'H' | 'R' | 'N' | 'C' | undefined
      dva: {
        referral: {
          providerNumber: string,
          providerName: string,
          referrerType: 'gp' | 'specialist',
          issueDateString: string,
          period: 'standard' | 'non-standard' | 'indefinite'
        },
    nonReferral: {
          reason: 'hospital' | 'lost' | 'emergency' | 'not-required' | 'remote-exemption',
          description: string
        }
      }
    }
  },
  {
    onSuccess: function(transaction) {
      /* ... */
    },
    onError: function(error) {
      /* ... */
    },
    onCancel: function() {
      /* ... */
    }
  }
);
`}</code></pre>
    <h4 {...{
      "id": "reference-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reference-3",
        "aria-label": "reference 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lspn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location Specific Practice Number.  LSPN may be required for diagnostic imaging items. When set, this value will be set on all claimed items.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isInHospital`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to true for admitted patient services. Defaults to false. Value applied for all claimed item.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facilityId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When isInHospital set to true, the Commonwealth Hospital Facility ID must be supplied which shares same format as a Medicare issued providerNumber.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`treatmentLocation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Strimg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`'V' (Home visit), 'H' (Hospital), 'R' (Rooms), 'N' (Residential Care facility), 'C' (Community Health Centres)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Referring provider number - usually a DVA issued number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.providerName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the referring provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.referrerType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the referring provider. 'gp' or 'specialist'.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.issueDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue date of the referral in YYYY-MM-DD format. Example “2019-01-25”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.period`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Period of the referral. Can be: 'standard', 'non-standard', 'indefinite'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.periodDescription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of months if `}<inlineCode parentName="td">{`referral.period`}</inlineCode>{` is set to 'non-standard'`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "claimable-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#claimable-items",
        "aria-label": "claimable items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claimable items`}</h4>
    <p>{`Additional item attributes such as admitted patient indicator, service text, Number Of Patients Seen and Duplicate Service Overrides can be set in the Medipass UI. A maximum of 16 items are permitted for patient claims and 80 items per bulk bill claim.`}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "hicaps-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hicaps-attributes",
        "aria-label": "hicaps attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HICAPS attributes`}</h3>
    <p>{`These additional fields apply to claims processed via HICAPS including private health insurance and overseas insurance.`}</p>
    <p>{`The following general field are not applicable for HICAPS claims and are ignored:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].description`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].quantity`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].unit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].isTaxable`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].extras`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "schema-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema-4",
        "aria-label": "schema 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction(
  {
    /* ... generic attributes from above */

    nonClaimableItems: [
      {
        description: string,
        price: string,
        isGross: boolean
      }
    ],

    funderData: {
      hicaps: {
        patient: {
          healthFundCode: string
        }
      }
    }
  },
  {
    onSuccess: function(transaction) {
      /* ... */
    },
    onError: function(error) {
      /* ... */
    },
    onCancel: function() {
      /* ... */
    }
  }
);
`}</code></pre>
    <h4 {...{
      "id": "reference-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reference-4",
        "aria-label": "reference 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nonClaimableItems`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array{Object}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of items paid by patient`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nonClaimableItems[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of good/service.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nonClaimableItems[].price`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Net (excluding GST) price`}</strong>{` each In $XX.XX format. Example ‘$15.95’. Note: If this is the gross amount (including GST), set the `}<inlineCode parentName="td">{`isGross`}</inlineCode>{` flag below to `}<inlineCode parentName="td">{`true`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nonClaimableItems[].isGross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to `}<inlineCode parentName="td">{`true`}</inlineCode>{`, this will indicate the `}<inlineCode parentName="td">{`price`}</inlineCode>{` is the gross amount (including GST).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.healthFundCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient Health Fund Code. See `}<a parentName="td" {...{
              "href": "/sdk/transaction/supported-funders"
            }}>{`Supported funders`}</a>{` for a list of valid health fund codes.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "claimable--non-claimable-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#claimable--non-claimable-items",
        "aria-label": "claimable  non claimable items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claimable & non-claimable items`}</h4>
    <p>{`Both claimable and non-claimable items can be set. Any gap amount (including all non-claimable items) will be processed as patient payment request via debit/credit cards. Note there is a maximum of 24 service items permitted per claim.`}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "icare-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#icare-attributes",
        "aria-label": "icare attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`icare attributes`}</h3>
    <p>{`These fields apply to claims processed via icare including workers insurance, motor accident and other funding schemes.`}</p>
    <p>{`The following general field are not applicable for icare claims and are ignored:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`patient.mobile`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`patient.email`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`patient.dob`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`patient.reference`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`nonClaimableItems`}</inlineCode></li>
    </ul>
    <p>{`The Medipass icare integration supports provider submission of supporting documentation, such as certificates of fitness/capacity, surgical/operation reports, allied health recovery requests and similar. However, these attachments must be added in the Medipass modal or iframe user interface.`}</p>
    <h4 {...{
      "id": "schema-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema-5",
        "aria-label": "schema 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction(
  {
    /* ... generic attributes from above */

    patient: {
      ...
      accountNumber: string, // maps to icare claim/approval number
      healthFundCode: string, // icare insurance scheme
      ...
    }

    funderData: {
      icare: {
        serviceNotes: string
      }
    }
  },
  {
    onSuccess: function(transaction) {
      /* ... */
    },
    onError: function(error) {
      /* ... */
    },
    onCancel: function() {
      /* ... */
    }
  }
);
`}</code></pre>
    <h4 {...{
      "id": "reference-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reference-5",
        "aria-label": "reference 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to "IWC" for Workers Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`serviceNotes`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`512`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Service notes for the transaction. Usually used for claims with exceptions.`}</td>
        </tr>
      </tbody>
    </table>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "medicare-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#medicare-attributes",
        "aria-label": "medicare attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medicare attributes`}</h3>
    <p>{`These additional fields apply to claims processed directly with Medicare including bulk bill and patient claims. For patient claims, there is also an option to set claimant details - where someone other than the patient pays for the service and receives the Medicare benefit. By default, the servicing provider will be set to the payee provider (the recipient for bulk bill payments). However, a different payee provider number can be specified at each transaction or as a default for the provider in the Medipass portal.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For all Medicare transactions, it’s important the patient and/or claimant details match exactly to that listed on a Medicare card rather than what may be recorded in your system. Medipass will prompt the user to verify and update details where account details vary from those registered with Medicare.`}</p>
    </blockquote>
    <p>{`The following general field are not applicable for Medicare claims and are ignored:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].description`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].quantity`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].unit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claimableItems[].isTaxable`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "schema-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema-6",
        "aria-label": "schema 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction(
  {
    /* ... generic attributes from above */

    funderData: {
      medicare: {
        isBulkBilled: boolean,
        lspn: string,
        facilityId: string,
        isInHospital: boolean,

        claimantPatientId: string,
        // OR
        claimant: {
      refId: string,
          firstName: string,
          lastName: string,
          mobile: string,
          dob: string,
          accountNumber: string,
          reference: string
        },

        referral: {
          providerNumber: string,
          providerName: string,
          referrerType: 'gp' | 'specialist',
          issueDateString: string,
          period: 'standard' | 'non-standard' | 'indefinite',
          periodDescription: number
        },
    nonReferral: {
          reason: 'hospital' | 'lost' | 'emergency' | 'not-required' | 'remote-exemption',
          description: string
        }
      }
    }
  },
  {
    onSuccess: function(transaction) {
      /* ... */
    },
    onError: function(error) {
      /* ... */
    },
    onCancel: function() {
      /* ... */
    }
  }
);
`}</code></pre>
    <h4 {...{
      "id": "reference-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reference-6",
        "aria-label": "reference 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isBulkBilled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Denotes if transaction is a bulk-bill claim (true) or patient claim (false).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lspn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location Specific Practice Number.  LSPN may be required for diagnostic imaging items. When set, this value will be set on all claimed items.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isInHospital`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to true for admitted patient services. Defaults to false. Value applied for all claimed item.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facilityId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When isInHospital set to true, the Commonwealth Hospital Facility ID must be supplied which shares same format as a Medicare issued providerNumber.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimantPatientId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass Business Patient ID. If supplied and valid, this value will pre-populate claimant details from Medipass records.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OR`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimant[].firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant first name as displayed on Medicare card. Middle name is not supported. If patient has only one name set first name field to ‘Onlyname’ and set name in lastName.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimant[].lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant last name as displayed on Medicare card.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimant[].mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile Phone number of the claimant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimant[].dob`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant Date of Birth in ‘YYYY-MM-DD’ format. Example: ‘1967-11-02’`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimant[].accountNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant Medicare card number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimant[].reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant Individual Reference Number from Medicare card eg 1, 2 etc. This number appears on the left of the cardholder's name on the medicare card and distinguishes the individuals named on the card.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Referring provider number - usually a Medicare issued number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.providerName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the referring provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.referrerType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the referring provider. 'gp' or 'specialist'.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.issueDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue date of the referral in YYYY-MM-DD format. Example “2019-01-25”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.period`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Period of the referral. Can be: 'standard', 'non-standard', 'indefinite'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral.periodDescription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of months if `}<inlineCode parentName="td">{`referral.period`}</inlineCode>{` is set to 'non-standard'`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "claimable-items-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#claimable-items-1",
        "aria-label": "claimable items 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claimable items`}</h4>
    <p>{`Additional item attributes such as admitted patient indicator, service text, Number Of Patients Seen and Duplicate Service Overrides can be set in the Medipass UI. A maximum of 16 items are permitted for patient claims and 80 items per bulk bill claim.`}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "workcover-queensland-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#workcover-queensland-attributes",
        "aria-label": "workcover queensland attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Workcover Queensland attributes`}</h3>
    <p>{`To process a Workcover Queensland workers insurance claim, the following fields apply. Any required field not passed to the SDK will be prompted at the Medipass submission page.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Max length`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Required?`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`funder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<strong parentName="td">{`wcq`}</strong>{` for Workcover Queensland claims.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For medical or allied healthcare providers, this is usually a Medicare issued provider number - 8 characters alpha-numeric such as 1234565X. If Medicare provider number is not applicable, Workcover Queensland account numbers (Pxxxxxxxxxx) for non-medical providers and AHPRA numbers are also supported if that same number was registered with Medipass.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceReference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider nominated unique transaction reference number, such as an invoice number from the practice management or accounting system.  Used for reconciliation purposed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-----`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient first name as registered with fund. Middle name is not supported. If patient has only one name, then set name in lastName field and set firstName to “Onlyname”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient last name as registered with fund.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.dob`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient Date of Birth in ‘YYYY-MM-DD’ format. Example: ‘1967-11-02’`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The mobile phone number of the patient. Can be either international notation: +61411111111 or national notation: 0411111111`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.accountNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to the Workcover Queensland issued Claim Number. Usually, these are formatted like `}<inlineCode parentName="td">{`SNNAANNNNNN`}</inlineCode>{` or `}<inlineCode parentName="td">{`DNNAANNNNNN`}</inlineCode>{` where N = numeric and A = alpha character.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.accidentDate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of accident/injury in 'YYYY-MM-DD' format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OR`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.refId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A patient unique reference as set by you. If supplied and valid, this value will pre-populate patient details from the patient record in the business as set in any prior claim. Any patient details sent for an existing patient record will update those values`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-----`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array{Object}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of fund claimable items.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique item reference for reconciliation in your system, such as `}<inlineCode parentName="td">{`001`}</inlineCode>{`. This reference must be unique for each item in the claim.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].serviceDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the service in 'YYYY-MM-DD' format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].itemCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Item code - note item code must be valid for funder, the provider registered profession and service date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].price`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Net (excluding GST) price each unit in $XX.XX format. Example ‘$15.95’.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].quantity`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Denotes number of services or pieces of equipment or other unit of measure as part of this service. Where funder has item code set to ignore quantity, field is ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].isTaxable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` if GST tax is applicable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].serviceText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional item level description or commentary.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].isGross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to `}<inlineCode parentName="td">{`true`}</inlineCode>{`, this will indicate the `}<inlineCode parentName="td">{`price`}</inlineCode>{` is the gross amount including GST tax for the item submitted. For example, if set to true and price is $11.00, item price excluding tax is $10.00 and GST of 10% / $1.00 is applied.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claimableItems[].isExtendedPrice`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, this will indicate the price is extended/aggregate price for all quantities submitted. For example, if set to true, and price is $20.00 with three quantity (effectively $6.66666 per unit) as sent to a funder. Otherwise, if set to false or omitted the price field will be multiplied per quantity claimed.  For example, if set to false, price is $20.00 with three quantity, each item price is $20.00 which will make extended price $60.00 as sent to a funder.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`At present, Workcover Queensland is not returning the outcome of a claim decision - approved, declined or otherwise. Updates on the claim and any payment remittance to providers will be supplied directly by Workcover Queenland either via email or their Provider Connect web portal. Providers can manually mark a claim as approved/paid in Medipass which will trigger a webhook event.`}</p>
    </blockquote>
    <h4 {...{
      "id": "schema-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#schema-7",
        "aria-label": "schema 7 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  funder: string,
  providerNumber: string,
  invoiceReference: string,
  patient: {
    refId: string,
    firstName: string,
    lastName: string,
    mobile: string,
    dob: string,
    accountNumber: string,
    accidentDate: string
  },

  claimableItems: [{
    reference: string,
    itemCode: string,
    serviceDateString: string,
    price: string,
    quantity: number,
    isTaxable: boolean,
    serviceText: string,
    isGross: boolean,
    isExtendedPrice: boolean
  }],

  webhooks: [{
    url: string,
    event:
      'invoiceCompleted' |
    method: 'GET' | 'PUT' | 'POST' | 'DELETE',
    headers: { [key: string]: string }
  }],

}, {
  onSuccess: function (transaction) { /* ... */ },
  onError: function (error) { /* ... */ },
  onCancel: function () { /* ... */ }
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      